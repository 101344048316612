import React, { PureComponent } from 'react';
import { urlUtil } from '@grafana/data';
import { ModalTabContent, Button, LinkButton, RadioButtonGroup, Field } from '@grafana/ui';
import { ShareModalTabProps } from 'app/features/dashboard/components/ShareModal';
import { NoRenderingInfoBox } from './NoRenderingInfoBox';
import { reportLayouts } from '../types';
import config from 'app/core/config';

type Props = ShareModalTabProps;

interface State {
  pdfUrl: string;
  selectedLayout: string;
}

export class SharePDF extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pdfUrl: '',
      selectedLayout: reportLayouts[0].value,
    };
  }

  componentDidMount() {
    this.buildPdfLink();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { selectedLayout } = this.state;
    if (prevState.selectedLayout !== selectedLayout) {
      this.buildPdfLink();
    }
  }

  buildPdfLink() {
    const { dashboard } = this.props;
    const { selectedLayout } = this.state;

    let rootPath = window.location.origin + config.appSubUrl;
    if (rootPath.endsWith('/')) {
      rootPath.slice(0, -1);
    }
    let pdfUrl = `${rootPath}/api/reports/render/pdf/${dashboard.id}`;

    const params: any = {};
    if (selectedLayout === 'landscape') {
      params['landscape'] = 'true';
    }
    pdfUrl = urlUtil.appendQueryToUrl(pdfUrl, urlUtil.toUrlParams(params));

    this.setState({ pdfUrl });
  }

  onLayoutChange = (value: string) => {
    this.setState({ selectedLayout: value });
  };

  render() {
    const { onDismiss } = this.props;
    const { pdfUrl, selectedLayout } = this.state;

    if (!config.rendererAvailable) {
      return <NoRenderingInfoBox />;
    }

    return (
      <ModalTabContent icon="file-alt">
        <p className="share-modal-info-text">Render dashboard as a PDF document.</p>
        <Field label="Layout">
          <RadioButtonGroup options={reportLayouts} value={selectedLayout} onChange={this.onLayoutChange} />
        </Field>
        <div className="gf-form-button-row">
          <LinkButton variant="primary" href={pdfUrl} target="blank">
            Save as PDF
          </LinkButton>
          <Button variant="secondary" onClick={onDismiss}>
            Cancel
          </Button>
        </div>
      </ModalTabContent>
    );
  }
}
