import React, { FC } from 'react';
import { TimeOfDayPicker, TimeZonePicker, Select, RadioButtonGroup, Field } from '@grafana/ui';
import { dateTime, DateTime, SelectableValue } from '@grafana/data';
import { daysOfWeek, daysOfMonth, SchedulingFrequency, SchedulingOptions } from '../types';

interface Props {
  schedulingOptions: SchedulingOptions;
  onModeChange: (mode: SchedulingFrequency) => void;
  onDayOfWeekChange: (day: SelectableValue<string>) => void;
  onDayOfMonthChange: (day: SelectableValue<string>) => void;
  onTimeOfDayChange: (time: DateTime) => void;
  onTimeZoneChange: (timeZone: string) => void;
}

const frequencyOptions: SelectableValue[] = [
  { label: 'Monthly', value: SchedulingFrequency.Monthly },
  { label: 'Weekly', value: SchedulingFrequency.Weekly },
  { label: 'Daily', value: SchedulingFrequency.Daily },
  { label: 'Hourly', value: SchedulingFrequency.Hourly },
  { label: 'Never', value: SchedulingFrequency.Never },
];

export const ReportScheduling: FC<Props> = ({
  onModeChange,
  onDayOfWeekChange,
  onDayOfMonthChange,
  onTimeOfDayChange,
  onTimeZoneChange,
  schedulingOptions,
}) => {
  const { frequency, hour, minute, timeZone } = schedulingOptions;

  const selectedDayOfWeek = daysOfWeek.find(day => schedulingOptions.day === day.value);
  const selectedDayOfMonth = daysOfMonth.find(day => schedulingOptions.dayOfMonth === day.value);

  return (
    <>
      <Field label="Frequency">
        <RadioButtonGroup options={frequencyOptions} onChange={onModeChange} value={frequency} />
      </Field>
      {frequency === SchedulingFrequency.Monthly && (
        <>
          <Field
            label="Day in month"
            description="If you set 29-31, the report will not be sent every month. Choose 'Last' to send the report on the last day of every month."
          >
            <Select onChange={onDayOfMonthChange} options={daysOfMonth} value={selectedDayOfMonth} />
          </Field>
        </>
      )}
      {frequency === SchedulingFrequency.Weekly && (
        <>
          <Field label="Day">
            <Select onChange={onDayOfWeekChange} options={daysOfWeek} value={selectedDayOfWeek} />
          </Field>
        </>
      )}
      {(frequency === SchedulingFrequency.Weekly || frequency === SchedulingFrequency.Monthly) && (
        <>
          <Field label="Time">
            <TimeOfDayPicker
              onChange={onTimeOfDayChange}
              minuteStep={10}
              value={dateTime(new Date(`January 1, 1970 ${hour}:${minute}`))}
            />
          </Field>
        </>
      )}
      {frequency === SchedulingFrequency.Daily && (
        <Field label="Time">
          <TimeOfDayPicker
            minuteStep={10}
            onChange={onTimeOfDayChange}
            value={dateTime(new Date(`January 1, 1970 ${hour}:${minute}`))}
          />
        </Field>
      )}
      {frequency === SchedulingFrequency.Hourly && (
        <Field label="At minute">
          <TimeOfDayPicker
            showHour={false}
            minuteStep={10}
            onChange={onTimeOfDayChange}
            value={dateTime(new Date(`January 1, 1970 ${hour}:${minute}`))}
          />
        </Field>
      )}
      {frequency !== SchedulingFrequency.Never && (
        <Field label="Time zone">
          <TimeZonePicker value={timeZone} onChange={onTimeZoneChange} />
        </Field>
      )}
    </>
  );
};
