import React, { PureComponent } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { css } from 'emotion';
import { DateTime, GrafanaTheme, NavModel, SelectableValue, urlUtil } from '@grafana/data';
import {
  Button,
  LinkButton,
  Input,
  TextArea,
  Form,
  Field,
  InputControl,
  ModalsController,
  Legend,
  FieldSet,
  stylesFactory,
  withTheme,
  HorizontalGroup,
  Themeable,
} from '@grafana/ui';

import { DashboardPicker } from 'app/core/components/Select/DashboardPicker';
import Page from 'app/core/components/Page/Page';
import { getRouteParamsId } from 'app/core/selectors/location';
import { getNavModel } from 'app/core/selectors/navModel';
import { ReportScheduling } from './ReportScheduling';
import { ReportOptionsPicker } from './ReportOptionsPicker';
import { createReport, loadReport, sendTestEmail, updateReport } from './state/actions';
import { EnterpriseStoreState, ReportOptions, ReportDTO, Report, SchedulingFrequency } from '../types';
import { validateMultipleEmails } from '../utils/validators';
import { config } from 'app/core/config';
import { SendTestEmailModal } from './SendTestEmailModal';
import { clearReportState, updateReportProp } from './state/reducers';

interface OwnProps extends Themeable {}

interface ConnectedProps {
  report: Report;
  navModel: NavModel;
  reportId?: number;
  isLoading: boolean;
}

interface DispatchProps {
  loadReport: typeof loadReport;
  clearReportState: typeof clearReportState;
  updateReport: typeof updateReport;
  createReport: typeof createReport;
  updateReportProp: typeof updateReportProp;
  sendTestEmail: typeof sendTestEmail;
}

export type Props = DispatchProps & ConnectedProps & OwnProps;

export class ReportPage extends PureComponent<Props> {
  componentDidMount() {
    const { loadReport, reportId } = this.props;
    if (reportId) {
      loadReport(reportId);
    }
  }

  componentWillUnmount() {
    this.props.clearReportState();
  }

  onModeChange = (frequency: SchedulingFrequency) => {
    const { report, updateReportProp } = this.props;

    updateReportProp({
      ...report,
      schedule: {
        ...report.schedule,
        frequency,
      },
    });
  };

  onDayOfWeekChange = (dayOfWeek: SelectableValue<string>) => {
    const { report, updateReportProp } = this.props;

    updateReportProp({
      ...report,
      schedule: {
        ...report.schedule,
        day: dayOfWeek.value as string,
      },
    });
  };

  onDayOfMonthChange = (dayOfMonth: SelectableValue<string>) => {
    const { report, updateReportProp } = this.props;

    updateReportProp({
      ...report,
      schedule: {
        ...report.schedule,
        dayOfMonth: dayOfMonth.value,
      },
    });
  };

  onTimeOfDayChange = (timeOfDay: DateTime) => {
    const { report, updateReportProp } = this.props;
    if (timeOfDay.hour && timeOfDay.minute) {
      updateReportProp({
        ...report,
        schedule: {
          ...report.schedule,
          hour: timeOfDay.hour(),
          minute: timeOfDay.minute(),
        },
      });
    }
  };

  onTimeZoneChange = (timeZone: string) => {
    const { report, updateReportProp } = this.props;

    updateReportProp({
      ...report,
      schedule: {
        ...report.schedule,
        timeZone,
      },
    });
  };

  onDashboardChange = (dashboard: SelectableValue<number>) => {
    const { report, updateReportProp } = this.props;
    if (dashboard) {
      updateReportProp({
        ...report,
        dashboardId: dashboard.id,
        dashboardName: dashboard.label as string,
      });
    } else {
      updateReportProp({
        ...report,
        dashboardId: 0,
        dashboardName: '',
      });
    }
  };

  onOptionsChange = (options: ReportOptions) => {
    const { report, updateReportProp } = this.props;
    updateReportProp({
      ...report,
      options,
    });
  };

  submitForm = (reportData: ReportDTO) => {
    const { createReport, updateReport, report, reportId } = this.props;
    const { schedule, options, dashboardId } = report;
    const { name, replyTo, recipients, message } = reportData;

    const createOrUpdate = reportId ? updateReport : createReport;

    const reportDto: ReportDTO = {
      id: report ? report.id : undefined,
      name,
      recipients,
      dashboardId,
      replyTo,
      message,
      schedule,
      options,
    };

    createOrUpdate(reportDto);
  };

  sendTestEmail = (reportData: ReportDTO) => (email: string, useEmailsFromReport: boolean) => {
    const { schedule, options, dashboardId } = this.props.report;
    const recipients = useEmailsFromReport ? reportData.recipients : email;

    const reportDto: ReportDTO = {
      ...reportData,
      schedule,
      options,
      dashboardId,
      recipients,
    };

    return sendTestEmail(reportDto);
  };

  getPreviewUrl() {
    const { report } = this.props;
    const { name, dashboardId, options } = report;

    if (!dashboardId) {
      return undefined;
    }

    const params: any = {
      title: name,
    };

    if (options.landscape) {
      // Use string param because macaron can't handle
      // bool param without value like http://grafana/api/render?landscape
      params.landscape = `${options.landscape}`;
    }

    return urlUtil.appendQueryToUrl(`api/reports/render/pdf/${dashboardId}`, urlUtil.toUrlParams(params));
  }

  render() {
    const { navModel, report, reportId, isLoading, theme } = this.props;
    const { message, name, recipients, replyTo, schedule, dashboardId, dashboardName, options } = report;
    const heading = reportId ? `Edit ${name}` : 'New report';
    const dashboardSelected = dashboardId > 0;
    const currentDashboard = dashboardSelected ? { value: dashboardId, label: dashboardName } : undefined;
    const previewUrl = this.getPreviewUrl();
    const styles = getStyles(theme);

    return (
      <Page navModel={navModel}>
        <Page.Contents isLoading={Boolean(isLoading && reportId)}>
          <Legend className={styles.header}>{heading}</Legend>
          <Form onSubmit={this.submitForm} validateOn="onBlur">
            {({ register, errors, control, formState, getValues }) => {
              return (
                <>
                  <Field label="Name" required invalid={!!errors.name} error="Name is required">
                    <Input
                      defaultValue={name}
                      name="name"
                      ref={register({ required: true })}
                      placeholder="System status report"
                    />
                  </Field>
                  <Field label="Choose dashboard" required invalid={!!errors.dashboardId} error="Dashboard is required">
                    <InputControl
                      name="dashboardId"
                      control={control}
                      as={DashboardPicker}
                      onSelected={(dashboard: SelectableValue) => {
                        this.onDashboardChange(dashboard);
                        // We need to manually set the form value for the form to trigger validation on change
                        control.setValue('dashboardId', dashboard?.id, true);
                      }}
                      defaultValue={currentDashboard?.value}
                      currentDashboard={currentDashboard}
                      rules={{ required: true }}
                      isClearable
                    />
                  </Field>
                  <Field label="Recipients" required invalid={!!errors.recipients} error={errors.recipients?.message}>
                    <TextArea
                      name="recipients"
                      ref={register({
                        required: 'Recipients are required',
                        validate: val => validateMultipleEmails(val) || 'Invalid email',
                      })}
                      placeholder="name@company.com;another.name@company.com"
                      defaultValue={recipients}
                    />
                  </Field>
                  <Field label="Reply to">
                    <Input
                      name="replyTo"
                      ref={register}
                      placeholder="your.address@company.com - optional"
                      type="email"
                      defaultValue={replyTo}
                    />
                  </Field>
                  <Field label="Custom message">
                    <TextArea name="message" placeholder={message} rows={10} ref={register} defaultValue={message} />
                  </Field>

                  <FieldSet label="Options">
                    <ReportOptionsPicker options={options} onChange={this.onOptionsChange} />
                  </FieldSet>

                  <FieldSet label="Scheduling">
                    <ReportScheduling
                      schedulingOptions={schedule}
                      onModeChange={this.onModeChange}
                      onDayOfWeekChange={this.onDayOfWeekChange}
                      onDayOfMonthChange={this.onDayOfMonthChange}
                      onTimeOfDayChange={this.onTimeOfDayChange}
                      onTimeZoneChange={this.onTimeZoneChange}
                    />

                    <HorizontalGroup spacing="md">
                      <Button type="submit" size="md" variant="primary">
                        Save
                      </Button>

                      <LinkButton
                        href={previewUrl}
                        size="xs"
                        target="_blank"
                        variant="secondary"
                        disabled={!config.rendererAvailable || !formState.isValid}
                      >
                        Preview
                      </LinkButton>
                      <ModalsController>
                        {({ showModal, hideModal }) => (
                          <Button
                            disabled={!formState.isValid}
                            size="xs"
                            variant="secondary"
                            onClick={e => {
                              e.preventDefault();
                              showModal(SendTestEmailModal, {
                                onDismiss: hideModal,
                                onSendTestEmail: this.sendTestEmail(getValues()),
                                emails: getValues().recipients,
                              });
                            }}
                          >
                            Send test email
                          </Button>
                        )}
                      </ModalsController>
                    </HorizontalGroup>
                  </FieldSet>
                </>
              );
            }}
          </Form>
        </Page.Contents>
      </Page>
    );
  }
}

const mapStateToProps: MapStateToProps<ConnectedProps, OwnProps> = (state: EnterpriseStoreState) => {
  const reportId = getRouteParamsId(state.location) as number;
  return {
    navModel: getNavModel(state.navIndex, 'reports-list'),
    report: state.reports.report,
    isLoading: state.reports.isLoading,
    reportId,
  };
};

const mapActionsToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  updateReport,
  loadReport,
  createReport,
  clearReportState,
  updateReportProp,
  sendTestEmail,
};

const getStyles = stylesFactory((theme: GrafanaTheme) => {
  return {
    header: css`
      font-size: ${theme.typography.heading.h2};
    `,
  };
});

export default connect(mapStateToProps, mapActionsToProps)(withTheme(ReportPage));
